import { handleErrors } from '../utils/error';
const commonHeaders = (csrfToken) => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Csrftoken': csrfToken,
    'X-Browser-Jwt-Auth': 'true',
});
const commonFetch = (endpoint, csrfToken, body = null, method = 'GET') => fetch(endpoint, {
    method,
    headers: commonHeaders(csrfToken),
    credentials: 'include',
    body,
})
    .then(handleErrors)
    .then((res) => {
    if (res.headers.get('Content-Type') !== 'application/json') {
        return true;
    }
    return res.json();
});
export function getServiceAccountList(url, csrfToken, org, pageSize = 500) {
    const endpoint = `${url}v1/orgs/${org}/services/?page_size=${pageSize}`;
    return commonFetch(endpoint, csrfToken);
}
